var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('translation-editor-dialog',{attrs:{"content":_vm.loctranslationDialog}}),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',[_c('v-col',{staticClass:"text-h5",attrs:{"cols":"12"}},[_vm._v(" Sorrend és láthatóság ")]),_c('draggable',{staticClass:"w-100",attrs:{"animation":"400"},model:{value:(_vm.availableHeaders),callback:function ($$v) {_vm.availableHeaders=$$v},expression:"availableHeaders"}},[_vm._l((_vm.availableHeaders),function(item,index){return [_c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"cursor-grab"},[_c('v-card-title',[_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(item.text)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-4",attrs:{"color":item.show ? 'primary' : 'warning'},on:{"click":function($event){item.show = !item.show}}},on),[_vm._v(" "+_vm._s(item.show ? 'mdi-table-eye' : 'mdi-table-eye-off')+" ")])]}}],null,true)},[_c('span',[_vm._v(" Láthatóság ")])])],1)],1)],1)]})],2)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{attrs:{"headers":_vm.availableHeaders,"hide-default-header":true,"items":_vm.datas,"search":_vm.search,"sort-by":['createdAt'],"sort-desc":['true'],"loading":_vm.loadingData,"loading-text":_vm.$t('loadingText.tableDataLoading'),"no-data-text":_vm.$t('noDataText.noEditableProjects'),"no-results-text":((_vm.$t('noResultText.tableNoResult')) + ": " + _vm.search)},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('draggable',{attrs:{"tag":"tr","animation":"200"},model:{value:(_vm.availableHeaders),callback:function ($$v) {_vm.availableHeaders=$$v},expression:"availableHeaders"}},[_vm._l((headers),function(item,index){return [_c('th',{directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}],key:index,staticClass:"cursor-grab pb-2",attrs:{"scope":"col"}},[_c('v-text-field',{staticClass:"font-weight-regular body-2 table-custom-column-search",attrs:{"height":"32"},model:{value:(item.searchText),callback:function ($$v) {_vm.$set(item, "searchText", $$v)},expression:"item.searchText"}}),_vm._v(" "+_vm._s(item.text)+" ")],1)]}),_c('th',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(_vm.$t('button.search'))+" ")]),_c('br'),_c('v-btn',{attrs:{"color":"primary","text":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('button.clearFilter'))+" ")])],1)],2)],1)]}},{key:"body",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('tbody',[_vm._l((_vm.datas),function(item){return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.search ? item.searchResult : true),expression:"search ? item.searchResult : true"}],key:item.id},[_vm._l((headers),function(header){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(header.show),expression:"header.show"}],key:header.value},[_vm._v(" "+_vm._s(item[header.value])+" ")])]}),_c('td',[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)],1)],2)]})],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }