<template>
  <v-container fluid>
    <translation-editor-dialog :content="loctranslationDialog"/>
    <v-row>
      <v-col cols="2">
        <v-row>
          <v-col cols="12" class="text-h5">
            Sorrend és láthatóság
          </v-col>
          <draggable v-model="availableHeaders" animation="400" class="w-100">
            <template v-for="(item, index) in availableHeaders">
              <v-col cols="12" :key="index">
                <v-card class="cursor-grab">
                  <v-card-title>
                    <span class="body-2">
                      {{ index + 1 }}. {{ item.text }}
                    </span>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{on}">
                        <v-icon class="mr-4"
                                @click="item.show = !item.show"
                                v-on="on"
                                :color="item.show ? 'primary' : 'warning'">
                          {{ item.show ? 'mdi-table-eye' : 'mdi-table-eye-off' }}
                        </v-icon>
                      </template>
                      <span>
                        Láthatóság
                      </span>
                    </v-tooltip>
                  </v-card-title>
                </v-card>
              </v-col>
            </template>
          </draggable>
        </v-row>
      </v-col>
      <v-col cols="10">
        <v-data-table
            :headers="availableHeaders"
            :hide-default-header="true"
            :items="datas"
            :search="search"
            :sort-by="['createdAt']"
            :sort-desc="['true']"
            :loading="loadingData"
            :loading-text="$t('loadingText.tableDataLoading')"
            :no-data-text="$t('noDataText.noEditableProjects')"
            :no-results-text="`${$t('noResultText.tableNoResult')}: ${search}`"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <draggable v-model="availableHeaders" tag="tr" animation="200">
                <template v-for="(item, index) in headers">
                  <th class="cursor-grab pb-2" :key="index" scope="col" v-show="item.show">
                    <v-text-field v-model="item.searchText"
                                  height="32"
                                  class="font-weight-regular body-2 table-custom-column-search"></v-text-field>
                    {{ item.text }}
                  </th>
                </template>
                <th>
                  <v-btn color="primary" small class="mb-2">
                    {{ $t('button.search') }}
                  </v-btn><br>
                  <v-btn color="primary" text small>
                    {{ $t('button.clearFilter') }}
                  </v-btn>
                </th>
              </draggable>
            </thead>
          </template>
          <template v-slot:body="{ items, headers }">
            <tbody>
              <template v-for="item in datas">
                <tr :key="item.id" v-show="search ? item.searchResult : true">
                  <template v-for="header in headers">
                    <td v-show="header.show" :key="header.value">
                      {{ item[header.value] }}
                    </td>
                  </template>
                  <td>
                    <v-btn icon color="primary">
                      <v-icon>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'DinamikusTablazat',
  components: {
    draggable, TranslationEditorDialog,
  },
  props: {
    //
  },
  data() {
    return {
      loctranslationDialog: {
        title: 'button.addNewTranslation',
        isVisible: false,
        selectedLanguageIndex: NaN,
        selectedTranslationIndex: NaN,
        selectedBoxindex: 'OFF',
        addOrEdit: 'ADD',
        translation: {
          buttonText: '',
          code: '',
          name: '',
          title: '',
          text: '<p>Írjunk ide valamit...</p>',
          text2: '<p>Írjunk is ide valamit...</p>',
        },
      },
      search: null,
      loadingData: false,
      datas: [
        {
          id: 11,
          createdAt: '2020. 08. 12.',
          gmsWorkSheetId: 1234,
          brandName: 'Samsung',
          productTypeName: 'S20+',
          status: 'Megjavítva',
          searchResult: false,
        },
        {
          id: 12,
          createdAt: '2020. 08. 12.',
          gmsWorkSheetId: 2345,
          brandName: 'Samsung',
          productTypeName: 'S10+',
          status: 'Árajánlat elkészült',
          searchResult: false,
        },
      ],
      headers: [
        { text: 'Azonosító', value: 'id' },
        { text: 'Dátum', value: 'createdAt' },
        { text: 'GSM Munkalapszám', value: 'gmsWorkSheetId' },
      ],
      availableHeaders: [
        {
          text: 'Azonosító', value: 'id', show: true, searchText: '',
        },
        {
          text: 'Dátum', value: 'createdAt', show: true, searchText: '',
        },
        {
          text: 'GSM Munkalapszám', value: 'gmsWorkSheetId', show: true, searchText: '',
        },
        {
          text: 'Gyártó', value: 'brandName', show: true, searchText: '',
        },
        {
          text: 'Típus', value: 'productTypeName', show: true, searchText: '',
        },
        {
          text: 'Státusz', value: 'status', show: true, searchText: '',
        },
      ],
    };
  },
  methods: {
    //
  },
  watch: {
    search(value) {
      // console.log('value: ', value);
    },
  },
};
</script>

<style scoped>

</style>
